<template>
    <div
        class="bc-wrapper"
        :class="settings.additionalClass ? settings.additionalClass : 'bc-horizontal'"
    >
        <div class="wrapper">
            <div class="breadcrumbs-wrapper">
                <div class="breadcrumbs-info">
                    <h1 class="page-title breadcrumbs-item">
                        <span class="title">
                            <div
                                v-if="backBtnPath || $route.meta.breadcrumbs.hasBack"
                                class="breadcrumbs-back"
                                @click="backBtnPath ? $router.push(backBtnPath) : $router.go(-1)"
                            >
                                <ChevronLeftIcon />
                            </div>
                            <span
                                class="p-semibold-weight"
                                v-html="settings.title"
                            />
                            <span
                                v-if="$route.name.includes('order_') && !isOrderForm"
                                class="order-details-header"
                            >
                                <span class="order-details-header__type">{{ settings.typeProject }}</span>
                                <span
                                    v-if="settings && settings.titleProject"
                                    class="order-details-header__title"
                                >«{{ settings.titleProject }}»</span>
                            </span>
                            <span
                                v-if="totalBalance"
                                class="bc_info_balance"
                            >{{ totalBalance | money }}</span>
                            <span
                                v-if="totalOrders"
                                class="bc_info_balance"
                            >{{ totalOrders | number }}</span>
                            <slot
                                class="bc_info_balance"
                                name="total"
                            />
                            <support-messenger-button
                                v-if="chatVisible"
                                :show-messenger="false"
                                @click="setShowMessenger(!getterShowMessenger)"
                            />
                        </span>
                    </h1>
                    <div class="breadcrumbs-row">
                        <p
                            v-if="settings.excerpt"
                            class="bc-wrapper-excerpt breadcrumbs-item"
                        >
                            {{ settings.excerpt }}
                        </p>
                        <slot name="rightSide" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ChevronRightIcon from 'mdi-vue/ChevronRight'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import filtersMixin from '@/mixins/filtersMixin.js'
import ChevronLeftIcon from 'mdi-vue/ChevronLeft'
import SupportMessengerButton from '@/components/support/supportMessenger/SupportMessengerButton.vue'

import {
    ACTION_SHOW_MESSENGER
} from '@/store/modules/messenger/action-types.js'

const {
    mapActions: mapMessengerActions
} = createNamespacedHelpers('messenger')

export default {
    name: 'Breadcrumbs',
    components: {
        ChevronLeftIcon,
        SupportMessengerButton
        // ReportAProblem
        // Bell
    },
    mixins: [
        filtersMixin
    ],
    props: {
        settings: {
            type: Object,
            required: true
        },
        showDetails: {
            type: Boolean,
            default: false
        },
        backBtnPath: {
            type: String
        },
        totalBalance: {
            type: Number,
            default: 0
        },
        totalOrders: {
            type: Number,
            default: 0
        },
        hidereport: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chatVisible: false
        }
    },
    computed: {
        ...mapGetters('messenger', [
            'getterShowMessenger'
        ]),
        isOrderForm() {
            console.log(this.backBtnPath);
            return this.$route.name.includes('order_new') || this.$route.name.includes('order_edit')
        }
    },
    mounted() {
        this.chatVisible = Boolean(localStorage.getItem('chat-hiden'))
    },
    methods: {
        ...mapMessengerActions([
            ACTION_SHOW_MESSENGER
        ]),
        isLinkToContent(val) {
            return val.includes(process.env.VUE_APP_API_CONTENT)
        },
        redirectToTheId() {
            this.$router.push({
                name: 'support_form'
            })
        },
        setShowMessenger(val) {
            this[ACTION_SHOW_MESSENGER](val)
        }
    }
}
</script>

<style lang="scss">
.breadcrumbs-item {
    .support-messenger__button {
        position: relative;
        top: initial;
        left: initial;
        right: initial;
        bottom: initial;
        margin-left: 20px;
        width: 50px;
        height: 50px;
        @media (max-width: 768px) {
            width: 40px;
            height: 40px;
        }
        .support-messenger__outside-close {
            display: none;
        }
    }
}
.breadcrumbs--item {
    display: flex;
    align-items: center;
}
.bc-wrapper {
    .wrapper {
        flex-wrap: wrap;
        .breadcrumbs-item{
            &:nth-child(2) {
                display: flex;
                justify-content: space-between;
            }
            width: 100%;
        }
    }
}
@media screen and (max-width: 600px){
    .bc-wrapper {
        padding-bottom: 100px;
        .wrapper {
            .breadcrumbs-item {
                &:nth-child(2) {
                    flex-wrap: wrap;
                    padding-right: 0 !important;
                    .report-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.report-a-problem{
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    span {
        font-size: 16px;
        font-weight: 300;
        margin-left: 5px;
    }
}
.breadcrumbs-row{
    display: flex;
    flex-wrap: wrap;
    .breadcrumbs-item{
        margin-right: auto;
    }
    @media all and (max-width: 1079px) {
        .breadcrumbs-btns{
            margin-top: 20px;
            width: 100%;
        }
    }
}

.order-details-header{
    display: flex;
    flex-direction: column;
    font-size: 24px;
    &__type {
        @media all and (max-width: 550px) {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    &__title {
        line-height: 1.5;
        font-weight: 300;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}

.order-form-new .breadcrumbs-info .page-title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}.order-form-new .breadcrumbs-info .breadcrumbs-row .bc-wrapper-excerpt{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: initial;
}
</style>
