<template
    lang="html"
    name="Header"
>
    <header
        id="header"
        class="mobile-menu"
    >
        <div class="mobile-menu__wrapper">
            <router-link :to="{ name: 'orders' }">
                <div
                    class="mobile-menu__item"
                    :class="$route.path === '/' ? 'mobile-menu__item--active' : ''"
                >
                    <div
                        class="mobile-menu__icon"
                        :class="`mobile-menu__icon-orders`"
                    />
                    <p>Projects</p>
                </div>
            </router-link>
            <router-link :to="{ name: 'tickets' }">
                <div
                    class="mobile-menu__item"
                    :class="$route.path === '/help-center' ? 'mobile-menu__item--active' : ''"
                >
                    <div
                        class="mobile-menu__icon"
                        :class="`mobile-menu__icon-tickets`"
                    />
                    <p>Help Center</p>
                </div>
            </router-link>
            <router-link
                v-if="getterCart.length === 0"
                :to="{ name: 'order' }"
            >
                <div
                    class="mobile-menu__item"
                >
                    <div
                        class="mobile-menu__icon"
                        :class="`mobile-menu__icon-order`"
                    />
                    <p>Order</p>
                </div>
            </router-link>
            <router-link
                v-else
                :to="{ name: 'checkout' }"
            >
                <div class="mobile-menu__item">
                    <div style="position: relative">
                        <div
                            class="mobile-menu__icon"
                            :class="`mobile-menu__icon-cart`"
                        />
                        <span class="mobile-menu__item--checkout-count ">{{ getterCart.length }}</span>
                    </div>
                    <p>Checkout</p>
                </div>
            </router-link>
            <router-link :to="{ name: 'notifications' }">
                <div
                    class=""
                >
                    <div
                        class="mobile-menu__item"
                        :class="$route.path === '/notifications' ? 'mobile-menu__item--active' : ''"
                    >
                        <div
                            class="mobile-menu__icon"
                            :class="`mobile-menu__icon-notifications`"
                        />
                        <p class="">
                            Notifications
                        </p>
                    </div>
                </div>
            </router-link>
            <div @click="showMenu = !showMenu">
                <div class="mobile-menu__item">
                    <div
                        class="mobile-menu__icon"
                        :class="`mobile-menu__icon-more`"
                    />
                    <p class="">
                        More
                    </p>
                </div>
                <control-panel-menu-mobile
                    :show-menu="showMenu"
                    @close="showMenu = false"
                />
            </div>
        </div>
    </header>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
// components
import ClickOutside from 'vue-click-outside'
import ControlPanelMenuMobile from '@/components/Header/ControlPanelMenuMobile'

import {
    ACTION_CREATE_BATCH
} from '@/store/modules/checkout/action-types.js'

const {
    mapActions: mapCheckoutActions
} = createNamespacedHelpers('checkout')

export default {
    directives: {
        ClickOutside
    },
    components: {
        ControlPanelMenuMobile
    },
    data() {
        return {
            showMenu: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo'
        ]),
        ...mapGetters('checkout', [
            'getterCart'
        ]),
        getAppDomain() {
            return process.env.VUE_APP_DOMAIN;
        },
        getAppName() {
            return process.env.APP_NAME;
        },
        getAppUrl() {
            return process.env.APP_URL;
        }
    },
    methods: {
        ...mapCheckoutActions([
            ACTION_CREATE_BATCH
        ])
    }
}
</script>
