<template>
    <button
        v-if="!getterIsAuth"
        class="btn-base btn-main cart-btn-empty cart-btn-animated"
        @click="notAuthClient()"
    >
        <div
            class="ink animate"
            style="height: 200px;width: 250px;top: -20px;left: -140px;"
        />
        <span class="cart-btn-empty__img">
            <order-new />
        </span>
        <span class="cart-btn-order-text">{{ $t('components.order_cart_button.text') }}</span>
    </button>
    <div
        v-else-if="getterPayment.orders > 0 || getterPayment.isLoading"
        class="btn-base btn-main cart-btn-animated cart-btn-order"
        @click="goToCheckout('checkout')"
    >
        <span
            style="font-size: 12px; margin: 0 4px;"
            class="cart-btn-order__cart"
        >
            checkout
        </span>
        <span
            v-if="!getterPayment.isLoading"
            class="cart-btn-order__cart"
        >
            <order-basket />
        </span>
        <span class="cart-btn-order__total bold">
            <ButtonLoader v-if="getterPayment.isLoading" />
            <span v-else>{{ getterPayment.payable | money }}</span>
        </span>
        <span class="cart-btn-order__count">
            {{ getterPayment.orders }}
        </span>
    </div>
    <div
        v-else
        id="new-order"
        class="btn-base btn-main cart-btn-empty cart-btn-animated"
        @click="goToCheckout('order')"
    >
        <div
            class="ink animate"
            style="height: 200px;width: 250px;top: -20px;left: -140px;"
        />
        <span class="cart-btn-empty__img">
            <order-new />
        </span>
        <span class="cart-btn-order-text">{{ $t('components.order_cart_button.text') }}</span>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import { mapGetters } from 'vuex'
import ButtonLoader from '@/components/common/ButtonLoader';
import OrderBasket from '@/components/icons/OrderBasket';
import OrderNew from '@/components/icons/OrderNew';
// mixin
import filtersMixin from '@/mixins/filtersMixin.js'
// vuex

export default {
    components: {
        ButtonLoader,
        OrderBasket,
        OrderNew
    },
    mixins: [filtersMixin],
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ]),
        ...mapGetters('checkout', [
            'getterPayment'
        ])
    },
    mounted() {
        console.log(this.getterPayment)
    },
    methods: {
        notAuthClient() {
            eventBus.$emit('showSnackBar', 'Please login before you place your order', 'info');
            this.$router.push({ name: 'login' })
        },
        goToCheckout(route) {
            this.$router.push({ name: route })
            this.$emit('goToCheckout')
        }
    }
}
</script>
<style lang="scss">
    .cart-btn-animated {
        width: calc(100% - 30px);
        height: 50px !important;
        padding: 0;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
        @include media768max {
            width: calc(100% - 15px);
            border-radius: 4px;
            // overflow: visible;
            padding: 0 2px;
            .cart-btn-order-text {
                display: none;
            }
            .cart-btn-empty__img {
                margin-right: 0;
            }
        }
        .ink {
            display: block;
            position: absolute;
            background: rgba(255, 255, 255, 1);
            border-radius: 100%;
            transform: scale(0);
            z-index: 20;
        }

        .animate {
            animation: ripple 4s linear infinite;
        }
        @keyframes ripple {
            20% {
                opacity: 0;
                transform: scale(2.5);
            }
            100% {
                opacity: 0;
                transform: scale(2.5);
            }
        }
    }
    .cart-btn-empty {
        border: none;

        &__img {
            height: 22px;
            margin-right: 10px;
            transition: all 0.3s ease-in-out;
            position: relative;
            top: -2px;
            >svg path{
                stroke: #fff;
            }
        }

        &:hover{
            .cart-btn-empty__img>svg path{
                stroke: $order-basket-svg-hover-color;
            }
        }
    }

    .cart-btn-order {
        overflow: visible;
        &:hover {
            // background: linear-gradient(270deg, #ff8645 0, #ff8645);
            .cart-btn-order__total{
                color: $order-basket-svg-hover-color;//#fff;
            }
            .cart-btn-order__cart>svg path{
                stroke: $order-basket-svg-hover-color;
            }
        }

        &:hover .cart-btn-order__count{
            color: $btn-main-background;
            background-color: $order-basket-svg-hover-color;
        }

        &__cart {
            margin-right: 10px;
            >svg path{
                stroke: #fff;
            }
            @include media768max {
                display: none;
            }
        }

        &__count {
            border-radius: 50%;
            background-color: $white;
            font-weight: 500;
            min-width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            color: $main-color;
            font-size: 14px;
            @include media768max {
                position: absolute;
                top: -10px;
                right: -5px;
                border: 1px solid $main-color;
            }
        }

        &__total {
            text-align: center;
            font-size: 14px;
            font-weight: 800;
            line-height: 18px;
            letter-spacing: 0.05em;
            @include media768max {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &__img {
            display: none;
        }
    }
    .btn-base_colored {
        box-shadow: $btn-base_colored-shadow;
    }
    //@media (max-width: 1200px) {
    //    a.cart-btn-animated {
    //        width: 60px;
    //        height: 60px !important;
    //        border-radius: 0;
    //    }
    //    .cart-btn {
    //        &-order-text {
    //            display: none;
    //        }
    //        &-empty__img {
    //            margin-right: 0;
    //        }
    //    }
    //    .cart-btn-order {
    //        &__cart {
    //            margin-right: 0;
    //        }
    //        &__count {
    //            position: absolute;
    //            top: 12px;
    //            right: 10px;
    //            min-width: 16px;
    //            min-height: 16px;
    //            width: 16px;
    //            height: 16px;
    //            font-size: 10px;
    //            line-height: 13px;
    //        }
    //        &__total {
    //            display: none;
    //        }
    //    }
    //}
    // @media (max-width: 576px) {
    //     .cart-btn-animated {
    //         margin-left: 15px;
    //         display: none;
    //     }
    // }
</style>
