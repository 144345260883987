<template>
    <div
        class="support-messenger__wrapper"
    >
        <transition
            mode="in-out"
            name="fade"
        >
            <div
                v-if="!getterShowMessenger && showPlaceholder"
                class="support-messenger__placeholder"
                :class="{'support-messenger__placeholder--is-auth' : getterIsAuth}"
                @click="setShowMessenger(!getterShowMessenger)"
            >
                <div class="support-messenger__placeholder-body">
                    <p>Hi there 👋</p>
                    <p>May I help you with your assignment?</p>
                </div>
                <div class="support-messenger__placeholder-footer">
                    <p>
                        <b>Operators </b>
                        and
                        <b>writers </b>are
                        ready to chat with you
                    </p>
                </div>
                <div
                    class="support-messenger__placeholder-close"
                    @click.stop="showPlaceholder= false"
                >
                    <img
                        :src="require('@/assets/img/svg/cross.svg')"
                        alt="close"
                    >
                </div>
            </div>
        </transition>

        <support-messenger-button
            v-if="!chatHiden"
            :show-messenger="getterShowMessenger"
            @click="setShowMessenger(!getterShowMessenger)"
            @hide="hideMessengerButton"
        />
        <transition
            mode="out-in"
            name="slide-y"
        >
            <supportMessenger
                v-if="getterShowMessenger"
                class="messenger_iframe"
                @closeMessenger="setShowMessenger(false)"
            />
        </transition>
    </div>
</template>
<script>
import { eventBus } from '@/helpers/event-bus/'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import supportMessenger from '@/views/supportMessenger/index.vue'
import ChangeTitle from '@/helpers/changeTitle.js'
import SupportMessengerButton from '@/components/support/supportMessenger/SupportMessengerButton.vue'
import Helper from '@/helpers/functions'

import {
    ACTION_SHOW_MESSENGER
} from '@/store/modules/messenger/action-types.js'

const file = require('@/assets/sounds/correct-answer.wav')

const {
    mapActions: mapMessengerActions
} = createNamespacedHelpers('messenger')

export default {
    components: {
        supportMessenger,
        SupportMessengerButton
    },
    data() {
        return {
            showPlaceholder: false,
            chatHiden: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterUnreadCounters'
        ]),
        ...mapGetters('messenger', [
            'getterShowMessenger'
        ]),
        operatorImage() {
            return Math.floor(Math.random() * 7) + 1
        },
        unreadCounter() {
            return this.getterUnreadCounters ? this.getterUnreadCounters.chats_count + this.getterUnreadCounters.open_tickets_count : 0
        }
    },
    watch: {
        getterShowMessenger(val) {
            if (this.showPlaceholder) {
                this.showPlaceholder = false
            }
            if (window.innerWidth <= 576 || window.innerHeight <= 880) {
                Helper.lockBody(val)
            } else {
                Helper.lockBody(false)
            }
        },
        shopUrl() {
            return process.env.VUE_APP_API_URL
        },
        getterIsAuth(newVal) {
            if (!newVal) {
                this.chatHiden = false
            } else {
                this.chatHiden = Boolean(localStorage.getItem('chat-hiden'))
            }
        }
    },
    mounted() {
        const hasPlaceholder = localStorage.getItem('chat-placeholder')
        this.chatHiden = Boolean(localStorage.getItem('chat-hiden')) && this.getterIsAuth
        if (!hasPlaceholder) {
            setTimeout(() => {
                this.showPlaceholder = true
                localStorage.setItem('chat-placeholder', new Date().toISOString())
            }, 5000)
        }
        window.addEventListener('message', (event) => {
            // if (event.origin !== this.shopUrl) return;
            if (event.data === 'closeChat') {
                this.setShowMessenger(false)
            }
        }, false);

        eventBus.$on('openMessenger', async (id, type = 'support-chat') => {
            this.setShowMessenger(true)
            this.$nextTick(() => {
                eventBus.$emit('openChat', id, type)
            })
        });
    },
    methods: {
        ...mapMessengerActions([
            ACTION_SHOW_MESSENGER
        ]),
        playSound() {
            const snd = new Audio(file);
            snd.play();
        },
        titleChange() {
            ChangeTitle.start('New message 🔔')
        },
        hideMessengerButton() {
            localStorage.setItem('chat-hiden', new Date().toISOString())
            this.chatHiden = true
        },
        setShowMessenger(val) {
            this[ACTION_SHOW_MESSENGER](val)
        }
    }
}
</script>

<style lang="scss">
    // @import "~@/assets/styles/common/components/_support-messenger";
    .messenger_iframe{
        position: fixed;
        bottom: 150px;
        left: 40px;
        width: 400px;
        max-width: 100%;
        height: 700px;
        z-index: 9999;
        border-radius: 15px;
        @media all and (max-width: 576px), (max-height: 880px) {
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            max-height: 100%;
            border-radius: 0;
        }
    }
</style>
